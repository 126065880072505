/**
 * @generated SignedSource<<4113b65faabc0689992f3032f1b8bacc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttributionWindowState = "ATTRIBUTION_WINDOW_STATE_NULL" | "ATTRIBUTION_WINDOW_STATE_SET" | "ATTRIBUTION_WINDOW_STATE_UNKNOWN" | "%future added value";
export type NewRevenueModelStatus = "NEW_REVENUE_MODEL_STATUS_CANDIDATE" | "NEW_REVENUE_MODEL_STATUS_DISABLED" | "NEW_REVENUE_MODEL_STATUS_ENABLED" | "NEW_REVENUE_MODEL_STATUS_UNKNOWN" | "NEW_REVENUE_MODEL_STATUS_UNSUPPORTED" | "%future added value";
export type UpdateAttributionSettingsInput = {
  companyId: string;
  emailClickWindow?: AttributionWindowInput | null;
  emailViewWindow?: AttributionWindowInput | null;
  filterMachineOpened?: boolean | null;
  newRevenueModel?: NewRevenueModelInput | null;
  textClickWindow?: AttributionWindowInput | null;
  textViewWindow?: AttributionWindowInput | null;
};
export type AttributionWindowInput = {
  attributionWindowState: AttributionWindowState;
  days?: number | null;
  hours?: number | null;
  value: number;
};
export type NewRevenueModelInput = {
  optInTimestamp: SerializedDateTime;
  status: NewRevenueModelStatus;
};
export type AttributionSettingsUpdateMutation$variables = {
  input: UpdateAttributionSettingsInput;
};
export type AttributionSettingsUpdateMutation$data = {
  readonly updateAttributionSettings: {
    readonly company: {
      readonly " $fragmentSpreads": FragmentRefs<"AttributionSettings_company">;
    } | null;
  } | null;
};
export type AttributionSettingsUpdateMutation$rawResponse = {
  readonly updateAttributionSettings: {
    readonly company: {
      readonly attributionSettings: {
        readonly emailClickWindow: {
          readonly value: number;
        };
        readonly emailViewWindow: {
          readonly attributionWindowState: AttributionWindowState;
          readonly value: number;
        };
        readonly filterMachineOpened: boolean;
        readonly textClickWindow: {
          readonly value: number;
        };
        readonly textViewWindow: {
          readonly attributionWindowState: AttributionWindowState;
          readonly value: number;
        };
      } | null;
      readonly id: string;
    } | null;
  } | null;
};
export type AttributionSettingsUpdateMutation = {
  rawResponse: AttributionSettingsUpdateMutation$rawResponse;
  response: AttributionSettingsUpdateMutation$data;
  variables: AttributionSettingsUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "attributionWindowState",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttributionSettingsUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAttributionSettingsPayload",
        "kind": "LinkedField",
        "name": "updateAttributionSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AttributionSettings_company"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttributionSettingsUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAttributionSettingsPayload",
        "kind": "LinkedField",
        "name": "updateAttributionSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AttributionSettings",
                "kind": "LinkedField",
                "name": "attributionSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "emailClickWindow",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "emailViewWindow",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filterMachineOpened",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "textClickWindow",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "textViewWindow",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e59714e2dc4c05f397970db7b7b55e63",
    "id": null,
    "metadata": {},
    "name": "AttributionSettingsUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation AttributionSettingsUpdateMutation(\n  $input: UpdateAttributionSettingsInput!\n) {\n  updateAttributionSettings(input: $input) {\n    company {\n      ...AttributionSettings_company\n      id\n    }\n  }\n}\n\nfragment AttributionSettings_company on Company {\n  id\n  attributionSettings {\n    emailClickWindow {\n      value\n    }\n    emailViewWindow {\n      value\n      attributionWindowState\n    }\n    filterMachineOpened\n    textClickWindow {\n      value\n    }\n    textViewWindow {\n      value\n      attributionWindowState\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1e92da7b5f6a8466498c6938f433796";

export default node;
